import { AxiosResponse } from 'axios'
import { http } from '@/core/classes/HTTP.class'

import {
    BillingGetAdditionalServicesResponseT,
    ClientCalculationPayload,
    GetClientCalculationResponse,
} from './types/billing'

const BASE_URL = process.env.VUE_APP_GATEWAY_URL

type ApiGetClientCalculationResponse = Promise<AxiosResponse<GetClientCalculationResponse>>

export function getClientCalculation(
    payload: ClientCalculationPayload
): ApiGetClientCalculationResponse {
    return http.post(`${BASE_URL}/billing/api/v2/calculators/client`, payload)
}

export function getAdditionalServicesBilling(
    companyId: number
): Promise<AxiosResponse<BillingGetAdditionalServicesResponseT>> {
    return http.get(`${BASE_URL}/billing/api/additional-services`, { params: { companyId } })
}

export default {
    getClientCalculation,
    getAdditionalServicesBilling,
}
