import { AxiosResponse } from 'axios'
import { http } from '@/core/classes/HTTP.class'

import {
    GetDirectionsResponse,
    GetDirectionsParams,
} from './types/direction'

const BASE_URL = process.env.VUE_APP_GATEWAY_URL

type ApiGetDirectionsResponse = Promise<AxiosResponse<GetDirectionsResponse>>

export function getDirections(params: GetDirectionsParams): ApiGetDirectionsResponse {
    return http.get('/direction', { params })
}

export default {
    getDirections
}