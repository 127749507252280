
import { computed, defineComponent, PropType } from 'vue'

import iCheckmark from '@/assets/icons/Checkmark.svg'

type Step = {
    id: number
    name: string
}

export default defineComponent({
    components: {
        'icon-checkmark': iCheckmark
    },
    props: {
        steps: {
            type: Array as PropType<Step[]>,
            default: () => []
        },
        activeStepId: {
            type: Number,
            default: null
        },
        completedStepsIds: {
            type: Array as PropType<number[]>,
            default: () => []
        },
        availableStepsIds: {
            type: Array as PropType<number[]>,
            default: () => []
        }
    },
    emits: ['update:activeStepId'],
    setup(props, { emit }) {
        const isStepActive = (step: Step) => {
            return step.id === props.activeStepId
        }

        const isStepCompleted = (step: Step) => {
            return props.completedStepsIds.includes(step.id)
        }

        const isStepAvailable = (step: Step) => {
            return props.availableStepsIds.includes(step.id)
        }

        const setActiveStep = (step: Step) => {
            if (!isStepAvailable(step)) return
            if (isStepActive(step)) return
            emit('update:activeStepId', step.id)
        }

        const completagePercent = computed(() => {
            const completed = props.completedStepsIds.length
            const total = props.steps.length

            return (completed / total) * 100
        })

        return {
            isStepActive,
            isStepCompleted,
            isStepAvailable,
            setActiveStep,
            completagePercent
        }
    }
})
