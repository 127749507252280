
import { defineComponent, reactive, watch } from 'vue'

// @types
import { CalculationAdditionalServicePayload } from '@/new-services/types/billing'

// @components
import SCheckbox from '@/common/components/SCheckbox/index.vue'
import NewButton from '@/common/new-components/NewButton/index.vue'

export default defineComponent({
    components: {
        SCheckbox,
        NewButton,
    },
    props: {
        setted: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['reset', 'submit'],
    setup(props, { emit }) {
        const state = reactive({
            hasManipulator: false,
            hasCrane: false,
            hasHydraulicTrolley: false,
            hasLoaders: false,
        })

        const onReset = () => {
            if (props.disabled) return

            state.hasManipulator = false
            state.hasCrane = false
            state.hasHydraulicTrolley = false
            state.hasLoaders = false

            emit('reset')
        }

        const getPayloadService = (code: string): CalculationAdditionalServicePayload => {
            return {
                code,
                value: 0,
                duration: 0,
                costPerHour: 0,
                costTotal: 0,
                paidPricePerHour: 0,
                paidPriceTotal: 0,
            }
        }

        const onSubmit = () => {
            if (props.disabled) return

            const services = [] as CalculationAdditionalServicePayload[]
            if (state.hasManipulator) {
                services.push(getPayloadService('manipulator'))
            }
            if (state.hasCrane) {
                services.push(getPayloadService('crane'))
            }
            if (state.hasHydraulicTrolley) {
                services.push(getPayloadService('hydraulic_trolley'))
            }
            if (state.hasLoaders) {
                services.push(getPayloadService('loader'))
            }

            emit('submit', services)
        }

        watch(
            () => props.setted,
            (is_setted: boolean) => {
                if (!is_setted) {
                    state.hasManipulator = false
                    state.hasCrane = false
                    state.hasHydraulicTrolley = false
                    state.hasLoaders = false
                }
            }
        )

        return {
            state,
            onReset,
            onSubmit,
        }
    },
})
