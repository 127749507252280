
import { defineComponent } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'

export default defineComponent({
    components: {
        's-loader': SLoader,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'submit',
        },
    },
    emits: ['click'],
    setup(props, { emit }) {
        const emitClick = () => {
            if (props.disabled || props.loading) return
            emit('click')
        }

        return { emitClick }
    },
})
