
import { computed, defineComponent, PropType } from 'vue'

// @components
import NewButton from '@/common/new-components/NewButton/index.vue'

import iEdit from '@/assets/icons/Edit.svg'
import iPerson from '@/assets/icons/Person.svg'
import iLocation from '@/assets/icons/Location.svg'

// @types
import { MemberT } from '@/core/types/common.types'


export default defineComponent({
    components: {
        NewButton,
        //
        'icon-edit': iEdit,
        'icon-person': iPerson,
        'icon-location': iLocation
    },
    props: {
        isReceiverTemplate: {
            type: Boolean,
            default: false,
        },
        template: {
            type: Object as PropType<MemberT>,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['delete', 'update', 'select'],
    setup(props, { emit }) {
        const isCanUpdate = computed(() => !props.disabled && !props.selected)
        const isCanDelete = computed(() => !props.disabled && !props.selected)
        const isCanSelect = computed(() => !props.disabled && !props.selected)

        const emitDelete = () => {
            if (isCanDelete.value) {
                emit('delete', { ...props.template })
            }
        }

        const emitUpdate = () => {
            if (isCanUpdate.value) {
                emit('update', { ...props.template })
            }
        }

        const emitSelect = () => {
            if (isCanSelect.value) {
                emit('select', { ...props.template })
            }
        }

        return {
            isCanUpdate,
            isCanDelete,
            isCanSelect,
            emitDelete,
            emitUpdate,
            emitSelect,
        }
    }
})
