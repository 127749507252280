
import { computed, defineComponent, onBeforeMount, PropType, reactive, toRaw } from 'vue'

// @components
import SModal from '@/common/components/SModal/index.vue'
import SInput from '@/common/components/SInput/index.vue'
import SLoader from '@/common/components/SLoader/index.vue'
import SPhoneNumber from '@/common/components/SPhoneNumber/index.vue'
import NewButton from '@/common/new-components/NewButton/index.vue'
import STextArea from '@/common/components/STextArea/index.vue'
import SCheckbox from '@/common/components/SCheckbox/index.vue'

import iClose from '@/assets/icons/Close.svg'
import iPerson from '@/assets/icons/Person.svg'
import iLocation from '@/assets/icons/Location.svg'
import iDots from '@/assets/icons/Dots.svg'
import iLattice from '@/assets/icons/Lattice.svg'
import iComment from '@/assets/icons/Comment.svg'

// @map-components
import SMap from '@/common/components/SMap/index.vue'
import SMapSearch from '@/common/components/SMapSearch/index.vue'

// @types
import {
    CityT,
    OptionT,
    ReceiverPayloadT,
    ReceiverT,
    SenderPayloadT,
    SenderT,
} from '@/core/types/common.types'
import { WarehouseT } from '@/core/types/Responses/Warehouses.responses'
import { MapSearchResultT } from '@/core/types/Map.types'

type AddressStateSetTypeT = {
    address: string | null
    street: string | null
    house: string | null
    latitude: number
    longitude: number
    is_warehouse: boolean
    warehouse_id: number | null
    is_finded: boolean
}

// @realisations
import { CitiesRealisation } from '@/core/realisations/Cities.realisation'
import { WarehousesRealisation } from '@/core/realisations/Warehouses.realisation'
import MapsRealisation from '@/core/realisations/Maps.realisation'

// @composable
import useNotifications from '@/common/composable/useNotifications'
import { useMaska } from '@/common/composable/useMaska'

// @validation
import useVuelidate from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'

// @constants
import { MAP_KEYS } from '@/core/constants/Map.constants'

const NOTIFICATIONS_GROUP = 'create-module'

const DELIVERY_TYPES = [
    {
        id: 1,
        name: 'Доставка курьером',
        value: 1,
    },
    // {
    //     id: 2,
    //     name: 'Забрать из Пункта выдачи заказов',
    //     value: 2,
    // },
    {
        id: 3,
        name: 'Самовывоз со склада Spark',
        value: 3,
    },
]

export default defineComponent({
    components: {
        SModal,
        SInput,
        SPhoneNumber,
        NewButton,
        STextArea,
        SCheckbox,
        SLoader,
        SMap,
        SMapSearch,
        //
        'icon-close': iClose,
        'icon-person': iPerson,
        'icon-location': iLocation,
        'icon-dots': iDots,
        'icon-lattice': iLattice,
        'icon-comment': iComment,
    },
    props: {
        isUpdate: {
            type: Boolean,
            default: false,
        },
        isReceiverTemplate: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        initialValue: {
            type: [Object, null] as PropType<SenderT | ReceiverT | null>,
            default: null,
        },
        loadCitiesBySenderCityId: {
            type: Boolean,
            default: false,
        },
        senderCityId: {
            type: [Number, null] as PropType<number | null>,
            default: null,
        },
        exactCityId: {
            type: [Number, null] as PropType<number | null>,
            default: null,
        },
    },
    emits: ['close', 'create-sender', 'create-receiver', 'update-sender', 'update-receiver'],
    setup(props, { emit }) {
        const citiesAPI = new CitiesRealisation()
        const warehousesAPI = new WarehousesRealisation()
        const mapsAPI = new MapsRealisation()

        const notifications = useNotifications()
        const { unmaskNumbers } = useMaska()

        const state = reactive({
            title: null as string | null,
            full_name: null as string | null,
            phone: null as string | null,
            additional_phone: null as string | null,
            city: null as CityT | null,
            delivery_type: null as OptionT<number> | null,
            full_address: null as string | null,
            latitude: null as number | null,
            longitude: null as number | null,
            index: null as string | null,
            office: null as string | null,
            comment: null as string | null,
            street: null as string | null,
            house: null as string | null,
            warehouse_id: null as number | null,
            distribution_center_id: null as number | null,
        })

        const state_form$ = useVuelidate(
            {
                title: { required, maxLength: maxLength(255) },
                full_address: { required },
                city: { required },
                full_name: { required, maxLength: maxLength(128) },
                phone: { required },
                additional_phone: { required },
                office: { maxLength: maxLength(50) },
                index: { maxLength: maxLength(50) },
            },
            state
        )

        const data_state = reactive({
            cities: {
                is_loading: false,
                is_failed: false,
                list: [] as CityT[],
            },
            warehouse: {
                is_selected: false,
                is_loading: false,
                is_failed: false,
                data: null as WarehouseT | null,
            },
        })

        const address_state = reactive({
            is_loading: false,
            is_failed: false,
            is_finded: false,
            address: null as string | null,
            street: null as string | null,
            house: null as string | null,
            latitude: 0,
            longitude: 0,
            is_warehouse: false,
            warehouse_id: null as number | null,
        })

        const map_state = reactive({
            opened: false,
            instance: null,
            is_yandex: true,
            is_gis: false,
            is_google: false,
        })

        let map_placemark = null as any

        const loadWarehouse = async (city_id: number) => {
            try {
                data_state.warehouse.is_loading = true

                const response = await warehousesAPI.getByCityId(city_id)
                data_state.warehouse.data = response.data

                if (
                    (props.isReceiverTemplate &&
                        state.delivery_type &&
                        state.delivery_type.value === 3) ||
                    (!props.isReceiverTemplate && data_state.warehouse.is_selected)
                ) {
                    state.street = data_state.warehouse.data.street
                    state.house = data_state.warehouse.data.house
                    state.latitude = Number(data_state.warehouse.data.latitude)
                    state.longitude = Number(data_state.warehouse.data.longitude)
                    state.full_address = data_state.warehouse.data.full_address
                    state.index = data_state.warehouse.data.index
                    state.office = data_state.warehouse.data.office
                    state.warehouse_id = data_state.warehouse.data.id
                }

                data_state.warehouse.is_failed = false
            } catch (error) {
                notifications.failure({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'При получении склада произошла ошибка',
                    text: 'Пожалуйста, попробуйте ещё раз',
                })

                console.error(error)
                data_state.warehouse.is_failed = true
            } finally {
                data_state.warehouse.is_loading = false
            }
        }

        const loadCities = async () => {
            try {
                data_state.cities.is_loading = true

                if (props.loadCitiesBySenderCityId && props.senderCityId) {
                    const response = await citiesAPI.getCitiesByCityId(props.senderCityId)
                    data_state.cities.list = response
                } else if (props.exactCityId) {
                    const response = await citiesAPI.getAllCities()
                    const city = response.find((c) => c.id === props.exactCityId)
                    if (city) {
                        data_state.cities.list = [city]
                    }
                } else {
                    const response = await citiesAPI.getAllCities()
                    data_state.cities.list = response
                }

                if (props.isUpdate && props.initialValue) {
                    const initial_value = props.initialValue as SenderT | ReceiverT
                    const city = data_state.cities.list.find((c) => c.id === initial_value.city_id)

                    if (city) {
                        state.city = { ...city }
                        loadWarehouse(city.id)
                    }
                }

                data_state.cities.is_failed = false
            } catch (error) {
                console.error(error)
                data_state.cities.is_failed = true

                notifications.failure({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'При получении списка городов произошла ошибка',
                    text: 'Пожалуйста, попробуйте ещё раз',
                })
            } finally {
                data_state.cities.is_loading = false
            }
        }

        const onClose = () => {
            if (props.loading) return
            emit('close')
        }

        const getSenderPayload = (): SenderPayloadT => {
            const city_id = (state.city as CityT).id
            const phone = `+${unmaskNumbers(state.phone as string)}`
            const additional_phone = `+${unmaskNumbers(state.additional_phone as string)}`

            return {
                title: state.title as string,
                entity: state.title as string,
                full_name: state.full_name as string,
                phone,
                additional_phone,
                city_id,
                full_address: state.full_address as string,
                latitude: state.latitude as number,
                longitude: state.longitude as number,
                index: state.index,
                office: state.office,
                comment: state.comment,
                street: state.street,
                house: state.house,
                warehouse_id: state.warehouse_id,
            }
        }

        const getReceiverPayload = (): ReceiverPayloadT => {
            const city_id = (state.city as CityT).id
            const phone = `+${unmaskNumbers(state.phone as string)}`
            const additional_phone = `+${unmaskNumbers(state.additional_phone as string)}`

            return {
                title: state.title as string,
                entity: state.title as string,
                full_name: state.full_name as string,
                phone,
                additional_phone,
                city_id,
                full_address: state.full_address as string,
                latitude: state.latitude as number,
                longitude: state.longitude as number,
                index: state.index,
                office: state.office,
                comment: state.comment,
                street: state.street,
                house: state.house,
                warehouse_id: state.warehouse_id,
                distribution_center_id: state.distribution_center_id,
            }
        }

        const onCreateSender = () => {
            state_form$.value.$touch()
            if (state_form$.value.$invalid) return

            const payload = getSenderPayload()
            emit('create-sender', payload)
        }

        const onCreateReceiver = () => {
            state_form$.value.$touch()
            if (state_form$.value.$invalid) return

            const payload = getReceiverPayload()
            emit('create-receiver', payload)
        }

        const onUpdateSender = () => {
            state_form$.value.$touch()
            if (state_form$.value.$invalid) return

            const payload = getSenderPayload()
            emit('update-sender', { id: (props.initialValue as SenderT).id, payload })
        }

        const onUpdateReceiver = () => {
            state_form$.value.$touch()
            if (state_form$.value.$invalid) return

            const payload = getReceiverPayload()
            emit('update-receiver', { id: (props.initialValue as ReceiverT).id, payload })
        }

        const onSubmit = () => {
            if (props.loading) return

            state_form$.value.$touch()
            if (state_form$.value.$invalid) return

            if (props.isReceiverTemplate) {
                if (props.isUpdate) {
                    onUpdateReceiver()
                    return
                }

                onCreateReceiver()
                return
            }

            if (props.isUpdate) {
                onUpdateSender()
                return
            }

            onCreateSender()
        }

        const initializeForm = () => {
            const initial_value = props.initialValue as SenderT | ReceiverT

            state.title = initial_value.title
            state.full_name = initial_value.full_name
            state.phone = initial_value.phone
            state.additional_phone = initial_value.additional_phone
            state.full_address = initial_value.full_address
            state.latitude = Number(initial_value.latitude)
            state.longitude = Number(initial_value.longitude)
            state.index = initial_value.index
            state.office = initial_value.office
            state.comment = initial_value.comment
            state.street = initial_value.street
            state.house = initial_value.house
            state.warehouse_id = initial_value.warehouse_id

            // ! City will be initialized in loadCities function

            if (state.warehouse_id) {
                data_state.warehouse.is_selected = true
                state.delivery_type = { ...DELIVERY_TYPES[1] }
            } else if (!state.distribution_center_id) {
                state.delivery_type = { ...DELIVERY_TYPES[0] }
            }
        }

        const initializeComponent = () => {
            loadCities()

            if (props.isUpdate && props.initialValue) {
                initializeForm()
            }
        }

        onBeforeMount(initializeComponent)

        const onToggleWarehouse = () => {
            if (props.loading) return

            if (data_state.warehouse.is_selected) {
                if (data_state.warehouse.data) {
                    state.street = data_state.warehouse.data.street
                    state.house = data_state.warehouse.data.house
                    state.latitude = Number(data_state.warehouse.data.latitude)
                    state.longitude = Number(data_state.warehouse.data.longitude)
                    state.full_address = data_state.warehouse.data.full_address
                    state.index = data_state.warehouse.data.index
                    state.office = data_state.warehouse.data.office
                    state.warehouse_id = data_state.warehouse.data.id
                }
            } else {
                state.full_address = null
                state.latitude = null
                state.longitude = null
                state.warehouse_id = null
            }
        }

        const onCityChange = (city: CityT | null) => {
            state.distribution_center_id = null

            if (!city) {
                data_state.warehouse.data = null
                data_state.warehouse.is_selected = false

                state.full_address = null
                state.latitude = null
                state.longitude = null
                state.warehouse_id = null

                return
            }

            loadWarehouse(city.id)
        }

        const onDeliveryTypeChange = (type: OptionT<number> | null) => {
            state.street = null
            state.house = null
            state.latitude = null
            state.longitude = null
            state.full_address = null
            state.index = null
            state.office = null
            state.warehouse_id = null

            if (!type) return

            if (type.value === 3 && data_state.warehouse.data) {
                state.street = data_state.warehouse.data.street
                state.house = data_state.warehouse.data.house
                state.latitude = Number(data_state.warehouse.data.latitude)
                state.longitude = Number(data_state.warehouse.data.longitude)
                state.full_address = data_state.warehouse.data.full_address
                state.index = data_state.warehouse.data.index
                state.office = data_state.warehouse.data.office
                state.warehouse_id = data_state.warehouse.data.id
            }
        }

        const setMapCenter = (coordinates: number[]) => {
            // @ts-ignore
            map_state.instance.panTo.bind(toRaw(map_state.instance))(coordinates)
        }

        const getCoordsByAddressYandex = async () => {
            if (!state.city) return

            if (state.city.latitude && state.city.longitude) {
                setMapCenter([state.city.latitude, state.city.longitude])

                if (state.city.coordinates) {
                    // @ts-ignore
                    toRaw(map_state.instance).options.set('restrictMapArea', state.city.coordinates)
                }

                return
            }

            try {
                const response = await mapsAPI.getYandexCoordsByAddress(state.city.name)

                if (!response) {
                    console.error('Не удалось получить координаты города', state.city.name)
                    return
                }

                setMapCenter(response)
            } catch (error) {
                console.error(error)
            }
        }

        const openMap = () => {
            state_form$.value.city.$touch()
            if (state_form$.value.city.$invalid) return

            map_state.opened = true

            if (map_state.instance) {
                initializeCoordinates()
            }
        }

        const closeMap = () => {
            map_state.opened = false
        }

        const setAddressState = ({
            address,
            latitude,
            longitude,
            street,
            house,
            warehouse_id,
            is_warehouse,
            is_finded,
        }: AddressStateSetTypeT) => {
            address_state.address = address
            address_state.latitude = latitude
            address_state.longitude = longitude
            address_state.street = street
            address_state.house = house

            address_state.is_warehouse = is_warehouse
            address_state.warehouse_id = warehouse_id

            address_state.is_finded = is_finded
        }

        const resetAddressState = () => {
            setAddressState({
                address: null,
                latitude: 0,
                longitude: 0,
                street: null,
                house: null,
                warehouse_id: null,
                is_warehouse: false,
                is_finded: false,
            })
        }

        const clearClickedPlacemark = () => {
            if (map_placemark) {
                // @ts-ignore
                map_state.instance.geoObjects.remove.bind(toRaw(map_state.instance.geoObjects))(
                    map_placemark
                )
                map_placemark = null
            }
        }

        const onWarehouseMarkClick = (warehouse: WarehouseT | null) => {
            if (!warehouse) return

            clearClickedPlacemark()
            const coordinates = [Number(warehouse.latitude), Number(warehouse.longitude)]
            setMapCenter(coordinates)

            setAddressState({
                address: warehouse.full_address,
                latitude: coordinates[0],
                longitude: coordinates[1],
                warehouse_id: warehouse.id,
                is_warehouse: true,
                street: warehouse.street,
                house: warehouse.house,
                is_finded: true,
            })
        }

        const setWarehouseMark = () => {
            if (!data_state.warehouse.data) return

            const coordinates = [
                Number(data_state.warehouse.data.latitude),
                Number(data_state.warehouse.data.longitude),
            ]

            // @ts-ignore
            const placemark = new window.ymaps.Placemark(
                coordinates,
                {
                    hintContent: `Склад (${data_state.warehouse.data.city})`,
                },
                {
                    preset: 'islands#blueHomeCircleIcon',
                }
            )

            placemark.events.add('click', () => {
                onWarehouseMarkClick(data_state.warehouse.data)
            })

            // @ts-ignore
            map_state.instance.geoObjects.add.bind(toRaw(map_state.instance.geoObjects))(placemark)
        }

        const setClickedMark = (coordinates: number[]) => {
            clearClickedPlacemark()

            // @ts-ignore
            map_placemark = new window.ymaps.Placemark(coordinates, null, {
                preset: 'islands#yellowDotIcon',
            })

            // @ts-ignore
            map_state.instance.geoObjects.add.bind(toRaw(map_state.instance.geoObjects))(
                map_placemark
            )
        }

        const initializeCoordinates = () => {
            // @ts-ignore
            map_state.instance.geoObjects.removeAll.bind(toRaw(map_state.instance.geoObjects))()

            if (data_state.warehouse.data) {
                setWarehouseMark()
            }

            if (!state.city) return

            if (!state.latitude || !state.longitude) {
                getCoordsByAddressYandex()
                return
            }

            if (!state.warehouse_id) {
                const coordinates = [state.latitude, state.longitude]

                setClickedMark(coordinates)
                setMapCenter(coordinates)

                setAddressState({
                    address: state.full_address,
                    latitude: state.latitude,
                    longitude: state.longitude,
                    warehouse_id: null,
                    is_warehouse: false,
                    street: state.street,
                    house: state.house,
                    is_finded: true,
                })
            }

            if (state.warehouse_id && data_state.warehouse.data) {
                if (state.warehouse_id === data_state.warehouse.data.id) {
                    onWarehouseMarkClick(data_state.warehouse.data)
                }
            }
        }

        const getClickedAdressYandex = async (coordinates: number[]) => {
            try {
                address_state.is_loading = true
                const response = await mapsAPI.getYandexAdressByCoords(coordinates)

                if (!response) return

                address_state.address = response.full_address
                address_state.street = response.street
                address_state.house = response.house

                address_state.is_failed = false
                address_state.is_finded = true
            } catch (error) {
                console.error(error)
                address_state.is_failed = true
            } finally {
                address_state.is_loading = false
            }
        }

        const getClickedAddressGis = async (coordinates: number[]) => {
            try {
                address_state.is_loading = true

                const response = await mapsAPI.geocodeAddressByGisCoords(coordinates)

                if (!response) return
                address_state.address = response.full_address
                address_state.street = response.street

                address_state.is_failed = false
                address_state.is_finded = true
            } catch (error) {
                console.error(error)
                address_state.is_failed = true
            } finally {
                address_state.is_loading = false
            }
        }

        const onMapClicked = (coordinates: number[]) => {
            if (address_state.is_loading) {
                return
            }

            setClickedMark(coordinates)
            setMapCenter(coordinates)

            setAddressState({
                latitude: coordinates[0],
                longitude: coordinates[1],
                address: null,
                street: null,
                house: null,
                is_warehouse: false,
                warehouse_id: null,
                is_finded: false,
            })

            if (map_state.is_yandex || map_state.is_google) getClickedAdressYandex(coordinates)
            if (map_state.is_gis) getClickedAddressGis(coordinates)
        }

        const onMapCreated = async (instance: any) => {
            map_state.instance = instance
        }

        const onMapSwitched = (map_key: string) => {
            if (map_key === MAP_KEYS.yandex) {
                map_state.is_yandex = true
                map_state.is_gis = false
                map_state.is_google = false
            }

            if (map_key === MAP_KEYS.gis) {
                map_state.is_gis = true
                map_state.is_yandex = false
                map_state.is_google = false
            }

            if (map_key === MAP_KEYS.google) {
                map_state.is_gis = false
                map_state.is_yandex = false
                map_state.is_google = true
            }
        }

        const confirmAddress = () => {
            state.full_address = address_state.address
            state.office = address_state.house
            state.house = address_state.house
            state.street = address_state.street

            state.latitude = address_state.latitude
            state.longitude = address_state.longitude

            state.warehouse_id = address_state.warehouse_id

            map_state.opened = false
            resetAddressState()

            if (state.warehouse_id) {
                state.delivery_type = DELIVERY_TYPES[1]
                data_state.warehouse.is_selected = true
                return
            } else {
                state.delivery_type = DELIVERY_TYPES[0]
                data_state.warehouse.is_selected = false
            }
        }

        const onFindedItemSelect = (result: MapSearchResultT) => {
            setClickedMark(result.points)
            setMapCenter(result.points)

            setAddressState({
                address: result.full_address,
                street: result.street,
                house: result.house,
                latitude: result.points[0],
                longitude: result.points[1],
                is_finded: true,
                warehouse_id: null,
                is_warehouse: false,
            })
        }

        const title = computed(() => {
            if (props.isUpdate) {
                if (props.isReceiverTemplate) {
                    return 'Редактирование получателя'
                }
                return 'Редактирование отправителя'
            }

            if (props.isReceiverTemplate) {
                return 'Создание получателя'
            }
            return 'Создание отправителя'
        })

        const labelOne = computed(() => {
            if (props.isReceiverTemplate) {
                return 'Укажите основную информацию о получателе'
            }
            return 'Укажите основную информацию об отправителе'
        })

        const labelTwo = computed(() => {
            if (props.isReceiverTemplate) {
                return 'Укажите информацию об адресе получателя и укажите на карте'
            }
            return 'Укажите информацию об адресе отправителя и укажите на карте'
        })

        const labelThree = computed(() => {
            if (props.isReceiverTemplate) {
                return 'Укажите контактные данные получателя'
            }
            return 'Укажите контактные данные отправителя'
        })

        const buttonTitle = computed(() => {
            if (props.isUpdate) {
                return 'Сохранить'
            }
            return 'Создать'
        })

        const templateTitlePlaceholder = computed(() => {
            if (props.isReceiverTemplate) {
                return 'Наименование получателя'
            }
            return 'Наименование отправителя'
        })

        const templateCheckboxLabel = computed(() => {
            if (props.isReceiverTemplate) {
                return 'Самовывоз'
            }
            return 'Самопривоз'
        })

        const isRenderingSenderControls = computed(() => {
            if (!props.isReceiverTemplate) {
                return true
            }
            if (props.isReceiverTemplate && state.delivery_type && state.delivery_type.id === 1) {
                return true
            }
            return false
        })

        const isRenderingWarehouseCheckbox = computed(() => !props.isReceiverTemplate)

        const isRenderingWarehouseCheckboxHint = computed(() => {
            if (
                !props.isReceiverTemplate &&
                data_state.warehouse.is_selected &&
                !data_state.warehouse.data
            ) {
                return true
            }
            return false
        })

        const isAddressButtonDisalbed = computed(() => {
            if (props.loading || data_state.cities.is_loading || data_state.warehouse.is_loading)
                return true
            if (!map_state.instance) return true
            return false
        })

        return {
            state,
            state_form$,
            data_state,
            address_state,
            map_state,
            //
            onClose,
            onSubmit,
            onToggleWarehouse,
            onCityChange,
            onDeliveryTypeChange,
            //
            openMap,
            closeMap,
            onMapCreated,
            onMapClicked,
            onMapSwitched,
            confirmAddress,
            onFindedItemSelect,
            isAddressButtonDisalbed,
            //
            title,
            labelOne,
            labelTwo,
            labelThree,
            buttonTitle,
            isRenderingSenderControls,
            isRenderingWarehouseCheckbox,
            isRenderingWarehouseCheckboxHint,
            //
            templateTitlePlaceholder,
            templateCheckboxLabel,
            //
            DELIVERY_TYPES,
        }
    },
})
