
import { computed, defineComponent, onBeforeMount, reactive } from 'vue'
import { useRouter } from 'vue-router'

import moment from 'moment'

// @components
import ProgressSteps from '@/modules/CalculatorModule/components/ProgressSteps/index.vue'
import SInput from '@/common/components/SInput/index.vue'
import iCheckmark from '@/assets/icons/Checkmark.svg'
import SLoader from '@/common/components/SLoader/index.vue'
import SCheckbox from '@/common/components/SCheckbox/index.vue'
import FileLoader from '@/common/components/FileLoader/index.vue'

import NewButton from '@/common/new-components/NewButton/index.vue'

import TemplatePanel from '@/modules/CalculatorModule/components/TemplatePanel/index.vue'
import TemplateForm from '@/modules/CalculatorModule/components/TemplateForm/index.vue'
import AdditionalServicesOnTake from '@/modules/CalculatorModule/components/AdditionalServicesOnTake/index.vue'
import AdditionalServicesOnDelivery from '@/modules/CalculatorModule/components/AdditionalServicesOnDelivery/index.vue'
import DateCalendar from '@/modules/CalculatorModule/components/DateCalendar/index.vue'
import PaymentType from '@/modules/CalculatorModule/components/PaymentType/index.vue'
import PaymentMethod from '@/modules/CalculatorModule/components/PaymentMethod/index.vue'

// @types
import {
    AdditionalServicesT,
    CityT,
    OptionT,
    PayerT,
    ReceiverPayloadT,
    ReceiverT,
    SenderPayloadT,
    SenderT,
} from '@/core/types/common.types'
import { Direction } from '@/new-services/types/direction'
import {
    CalculationAdditionalServicePayload,
    ClientCalculation,
    ClientCalculationPayload,
} from '@/new-services/types/billing'
import { LTLCalendarT } from '@/core/types/LTL.types'

// @realisations
import { CitiesRealisation } from '@/core/realisations/Cities.realisation'
import { SendersRealisation } from '@/core/realisations/Senders.realisation'
import { ReceiversRealisation } from '@/core/realisations/Receivers.realisation'
import { UsersRealisation } from '@/core/realisations/Users.realisation'
import { InvoicesRealisation } from '@/core/realisations/Invoices.realisation'

// @new-services
import billingAPI from '@/new-services/billing.service'
import directionsAPI from '@/new-services/direction.service'

// @composable
import { useTitle } from 'vue-composable'
import useNotifications from '@/common/composable/useNotifications'
import { useFormData } from '@/common/composable/useFormData'

// @constants
const PROGRESS_STEPS = [
    {
        id: 1,
        name: 'Предварительный расчет',
    },
    {
        id: 2,
        name: 'Оформление заказа',
    },
    {
        id: 3,
        name: 'О грузе',
    },
    {
        id: 4,
        name: 'Оплата',
    },
    {
        id: 5,
        name: 'Подтверждение',
    },
]
const NOTIFICATIONS_GROUP = 'create-module'

// @validation
import useVuelidate from '@vuelidate/core'
import { required, requiredIf, decimal, integer, maxLength } from '@vuelidate/validators'

export default defineComponent({
    components: {
        ProgressSteps,
        SInput,
        'icon-checkmark': iCheckmark,
        SLoader,
        TemplatePanel,
        TemplateForm,
        AdditionalServicesOnDelivery,
        AdditionalServicesOnTake,
        DateCalendar,
        SCheckbox,
        PaymentType,
        PaymentMethod,
        FileLoader,
        NewButton,
    },
    setup() {
        useTitle('Калькулятор')

        const notifications = useNotifications()
        const router = useRouter()
        const { getFormData } = useFormData()

        const citiesAPI = new CitiesRealisation()
        const sendersAPI = new SendersRealisation()
        const receiversAPI = new ReceiversRealisation()
        const usersAPI = new UsersRealisation()
        const invoicesAPI = new InvoicesRealisation()

        const state = reactive({
            current_step: PROGRESS_STEPS[0].id,
            completed_steps: [] as number[],
            available_steps: [1] as number[],
            is_order_creating: false,
        })

        const data_state = reactive({
            from_cities: {
                is_loading: false,
                is_failed: false,
                options: [] as CityT[],
            },
            to_cities: {
                is_loading: false,
                is_failed: false,
                options: [] as CityT[],
            },
            direction: {
                is_loading: false,
                is_failed: false,
                data: null as Direction | null,
            },
            senders: {
                is_loading: false,
                is_failed: false,
                list: [] as SenderT[],
                current_page: 1,
                total_pages: 1,
            },
            receivers: {
                is_loading: false,
                is_failed: false,
                list: [] as ReceiverT[],
                current_page: 1,
                total_pages: 1,
            },
            payers: {
                is_loading: false,
                is_failed: false,
                list: [] as PayerT[],
            },
            verify: {
                type: 0 as number,
                changed_by_user: false,
                is_loading: false,
                is_failed: false,
            },
        })

        const billing_state = reactive({
            is_calculating: false,
            selected_tariff: null as ClientCalculation | null,
            auto: {
                is_loading: false,
                is_failed: false,
                tariff: null as ClientCalculation | null,
            },
            avia: {
                is_loading: false,
                is_failed: false,
                tariff: null as ClientCalculation | null,
            },
        })

        const template_form = reactive({
            is_opened: false,
            is_loading: false,
            is_receiver_template: false,
            is_update: false,
            initial_value: null as SenderT | ReceiverT | null,
        })

        const loadSenders = async (from_city_id: number) => {
            data_state.senders.total_pages = 1
            data_state.senders.list = []

            try {
                data_state.senders.is_loading = true

                const response = await sendersAPI.getAll(1, from_city_id)

                data_state.senders.list = response.data.senders
                data_state.senders.total_pages = response.data.total_pages

                data_state.senders.is_failed = false
            } catch (error) {
                console.error(error)
                data_state.senders.is_failed = true

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'При получении списка отправителей произошла ошибка',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    },
                    error
                )
            } finally {
                data_state.senders.is_loading = false
            }
        }

        const loadMoreSenders = async () => {
            if (!advance_paynemt_state.city_from) return

            data_state.senders.current_page += 1

            try {
                data_state.senders.is_loading = true

                const fromCityId = advance_paynemt_state.city_from.id
                const response = await sendersAPI.getAll(
                    data_state.senders.current_page,
                    fromCityId
                )

                data_state.senders.list = data_state.senders.list.concat(response.data.senders)
                data_state.senders.total_pages = response.data.total_pages

                data_state.senders.is_failed = false
            } catch (error) {
                console.error(error)
                data_state.senders.is_failed = true

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'При получении списка отправителей произошла ошибка',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    },
                    error
                )
            } finally {
                data_state.senders.is_loading = false
            }
        }

        const searchSender = async (query: string | null) => {
            if (!advance_paynemt_state.city_from) return

            const fromCityId = advance_paynemt_state.city_from.id

            if (!query) {
                loadSenders(fromCityId)
                return
            }

            data_state.senders.total_pages = 1
            data_state.senders.list = []

            try {
                data_state.senders.is_loading = true

                const response = await sendersAPI.search(query, fromCityId)
                data_state.senders.list = response.data

                data_state.senders.is_failed = false
            } catch (error) {
                console.error(error)
                data_state.senders.is_failed = true

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'При поиске отправителя произошла ошибка',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    },
                    error
                )
            } finally {
                data_state.senders.is_loading = false
            }
        }

        const loadReceivers = async (where_city_id: number) => {
            data_state.receivers.current_page = 1
            data_state.receivers.list = []

            try {
                data_state.receivers.is_loading = true

                const response = await receiversAPI.getList({
                    page_number: 1,
                    city_id: where_city_id,
                })

                data_state.receivers.list = response.receivers
                data_state.receivers.total_pages = response.total_pages

                data_state.receivers.is_failed = false
            } catch (error) {
                console.error(error)
                data_state.receivers.is_failed = true

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'При получении списка получателей произошла ошибка',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    },
                    error
                )
            } finally {
                data_state.receivers.is_loading = false
            }
        }

        const loadMoreReceivers = async () => {
            if (!advance_paynemt_state.city_to) return

            data_state.receivers.current_page += 1

            try {
                data_state.receivers.is_loading = true

                const whereCityId = advance_paynemt_state.city_to.id
                const response = await receiversAPI.getList({
                    page_number: data_state.receivers.current_page,
                    city_id: whereCityId,
                })

                data_state.receivers.list = data_state.receivers.list.concat(response.receivers)
                data_state.receivers.total_pages = response.total_pages

                data_state.receivers.is_failed = false
            } catch (error) {
                console.error(error)
                data_state.receivers.is_failed = true

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'При получении списка получателей произошла ошибка',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    },
                    error
                )
            } finally {
                data_state.receivers.is_loading = false
            }
        }

        const searchReceiver = async (query: string | null) => {
            if (!advance_paynemt_state.city_to) return

            const whereCityId = advance_paynemt_state.city_to.id

            if (!query) {
                loadReceivers(whereCityId)
                return
            }

            data_state.receivers.total_pages = 1
            data_state.receivers.list = []

            try {
                data_state.receivers.is_loading = true

                const response = await receiversAPI.search(query, whereCityId)
                data_state.receivers.list = response.data.receivers

                data_state.receivers.is_failed = false
            } catch (error) {
                console.error(error)
                data_state.receivers.is_failed = true

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'При поиске получателя произошла ошибка',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    },
                    error
                )
            } finally {
                data_state.receivers.is_loading = false
            }
        }

        const loadFromCities = async () => {
            try {
                data_state.from_cities.is_loading = true

                const response = await citiesAPI.getAllCities()
                data_state.from_cities.options = response

                data_state.from_cities.is_failed = false
            } catch (error) {
                data_state.from_cities.is_failed = true
                console.error(error)

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'При получении списка городов произошла ошибка',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    },
                    error
                )
            } finally {
                data_state.from_cities.is_loading = false
            }
        }

        const recalculateTariffs = () => {
            if (!billing_state.is_calculating) return

            advance_paynemt$.value.$touch()

            if (!data_state.direction) {
                state.current_step = PROGRESS_STEPS[0].id

                notifications.alert({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Направление не установлено',
                    text: 'Пожалуйста, попробуйте ещё раз',
                })

                return
            }

            if (!advance_paynemt_state.height) {
                state.current_step = PROGRESS_STEPS[0].id

                notifications.alert({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Не указана высота',
                    text: 'Пожалуйста, укажите высоту',
                })

                return
            }

            if (!advance_paynemt_state.length) {
                state.current_step = PROGRESS_STEPS[0].id

                notifications.alert({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Не указана длина',
                    text: 'Пожалуйста, укажите длину',
                })

                return
            }

            if (!advance_paynemt_state.places) {
                state.current_step = PROGRESS_STEPS[0].id

                notifications.alert({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Не указано количество мест',
                    text: 'Пожалуйста, укажите количество мест',
                })

                return
            }

            if (!advance_paynemt_state.weight) {
                state.current_step = PROGRESS_STEPS[0].id

                notifications.alert({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Не указан вес',
                    text: 'Пожалуйста, укажите вес',
                })

                return
            }

            if (!advance_paynemt_state.width) {
                state.current_step = PROGRESS_STEPS[0].id

                notifications.alert({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Не указана ширина',
                    text: 'Пожалуйста, укажите ширину',
                })

                return
            }

            loadAutoCalculation()
            loadAviaCalculation()
        }

        const loadToCities = async (from_city: CityT | null) => {
            advance_paynemt_state.city_to = null

            data_state.to_cities.options = []
            data_state.direction.data = null

            ordering_state.sender = null
            data_state.senders.list = []
            data_state.senders.current_page = 1
            data_state.senders.total_pages = 1

            ordering_state.receiver = null
            data_state.receivers.list = []
            data_state.receivers.current_page = 1
            data_state.receivers.total_pages = 1

            billing_state.auto.tariff = null
            billing_state.avia.tariff = null

            if (!from_city) return

            try {
                data_state.to_cities.is_loading = true

                const from_city_id = from_city.id
                const response = await citiesAPI.getCitiesByCityId(from_city_id)

                data_state.to_cities.options = response
                loadSenders(from_city_id)

                data_state.to_cities.is_failed = false
            } catch (error) {
                data_state.to_cities.is_failed = true
                console.error(error)

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'При получении списка городов произошла ошибка',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    },
                    error
                )
            } finally {
                data_state.to_cities.is_loading = false
            }
        }

        onBeforeMount(() => {
            loadFromCities()
            loadVerifyType()
        })

        const advance_paynemt_state = reactive({
            city_from: null as CityT | null,
            city_to: null as CityT | null,
            weight: null as string | null,
            places: null as string | null,
            length: null as string | null,
            height: null as string | null,
            width: null as string | null,
        })

        const advance_paynemt$ = useVuelidate(
            {
                city_from: { required },
                city_to: { required },
                weight: { decimal, required },
                places: { required, integer },
                length: { decimal, required },
                height: { decimal, required },
                width: { decimal, required },
            },
            advance_paynemt_state
        )

        const ordering_state = reactive({
            additionalServicesTake: [] as CalculationAdditionalServicePayload[],
            is_additional_services_on_take_setted: false,
            additionalServicesDelivery: [] as CalculationAdditionalServicePayload[],
            is_additional_services_on_delivery_setted: false,
            sender: null as SenderT | null,
            receiver: null as ReceiverT | null,
            take_date: null as Date | null,
            take_period: null as OptionT<string> | null,
            is_date_setted: false,
        })

        const about_cargo_state = reactive({
            product_name: null as string | null,
            customer_invoice_number: null as string | null,
            cod_payment: null as string | null,
            annotation: null as string | null,
            //
            is_insurance: false,
            verify: false,
            is_return_documents: false,
            //
            insurance_cargo_name: null as string | null,
            declared_price: null as string | null,
            //
            is_dangerous: false,
            invoice_files: [],
            certificate_of_safety_files: [],
            is_files_loading: false,
            is_temperature_regime: false,
            temperature_regime: null as string | null,
            //
            hasGrid: false,
            hasPallet: false,
            hasSoftPackage: false,
        })

        const about_cargo$ = useVuelidate(
            {
                product_name: { required },
                customer_invoice_number: {
                    required: requiredIf(
                        () => data_state.verify.type === 2 && about_cargo_state.verify
                    ),
                    maxLength: maxLength(30),
                },
                cod_payment: { required, decimal },
                insurance_cargo_name: {
                    required: requiredIf(() => about_cargo_state.is_insurance),
                },
                declared_price: {
                    required: requiredIf(() => about_cargo_state.is_insurance),
                    decimal,
                },
                temperature_regime: {
                    required: requiredIf(() => about_cargo_state.is_temperature_regime),
                    decimal,
                },
                invoice_files: { required: requiredIf(() => about_cargo_state.is_dangerous) },
                certificate_of_safety_files: {
                    required: requiredIf(() => about_cargo_state.is_dangerous),
                },
            },
            about_cargo_state
        )

        const payment_state = reactive({
            payment_type: null as number | null,
            payment_method: null as number | null,
            payer: null as PayerT | null,
        })

        const payment$ = useVuelidate(
            {
                payer: {
                    required: requiredIf(
                        () =>
                            (payment_state.payment_type === 2 ||
                                payment_state.payment_type === 3) &&
                            payment_state.payment_method === 3
                    ),
                },
            },
            payment_state
        )

        const setAdditionalServicesOnDelivery = (
            services: CalculationAdditionalServicePayload[]
        ) => {
            ordering_state.additionalServicesDelivery = services
            ordering_state.is_additional_services_on_delivery_setted = true
            recalculateTariffs()
        }

        const resetAdditionalServicesOnDelivery = () => {
            ordering_state.additionalServicesDelivery = []
            ordering_state.is_additional_services_on_delivery_setted = false
            recalculateTariffs()
        }

        const setAdditionalServicesOnTake = (services: CalculationAdditionalServicePayload[]) => {
            ordering_state.additionalServicesTake = services
            ordering_state.is_additional_services_on_take_setted = true
            recalculateTariffs()
        }

        const resetAdditionalServicesOnTake = () => {
            ordering_state.additionalServicesTake = []
            ordering_state.is_additional_services_on_take_setted = false
            recalculateTariffs()
        }

        const onSelectSender = (sender: SenderT) => {
            ordering_state.sender = sender
            loadPayers()
            recalculateTariffs()
        }

        const onDeleteSender = async (sender: SenderT) => {
            const answer = confirm(
                `Вы действительно хотите удалить отправителя '${sender.title}' ?`
            )
            if (!answer) return

            try {
                await sendersAPI.delete(sender.id as number)

                const index = data_state.senders.list.findIndex(
                    (template) => template.id === sender.id
                )
                if (index >= 0) {
                    data_state.senders.list.splice(index, 1)
                }

                notifications.success({
                    group: NOTIFICATIONS_GROUP,
                    type: 'success',
                    title: 'Отправитель удален',
                    text: `Отправитель '${sender.title}' удален`,
                })
            } catch (error) {
                console.error(error)

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'При удалении отправителя произошла ошибка',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    },
                    error
                )
            }
        }

        const onSelectReceiver = (receiver: ReceiverT) => {
            ordering_state.receiver = receiver
            recalculateTariffs()
        }

        const onDeleteReceiver = async (receiver: ReceiverT) => {
            const answer = confirm(
                `Вы действительно хотите удалить получателя '${receiver.title}' ?`
            )
            if (!answer) return

            try {
                await receiversAPI.delete(receiver.id as number)

                const index = data_state.receivers.list.findIndex(
                    (template) => template.id === receiver.id
                )
                if (index >= 0) {
                    data_state.receivers.list.splice(index, 1)
                }

                notifications.success({
                    group: NOTIFICATIONS_GROUP,
                    type: 'success',
                    title: 'Получатель удален',
                    text: `Получатель '$    {receiver.title}' удален`,
                })
            } catch (error) {
                console.error(error)

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'При удалении получателя произошла ошибка',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    },
                    error
                )
            }
        }

        const loadAutoCalculation = async () => {
            billing_state.auto.tariff = null

            if (!data_state.direction.data) {
                return
            }

            try {
                billing_state.auto.is_loading = true

                let hasPickup = 0
                let hasSelfDelivery = 0

                if (ordering_state.sender && ordering_state.sender.warehouse_id) {
                    hasSelfDelivery = 1
                }

                if (ordering_state.receiver && ordering_state.receiver.warehouse_id) {
                    hasPickup = 1
                }

                const payload: ClientCalculationPayload = {
                    isFromMainCity: data_state.direction.data.is_from_main_city,
                    isNearbyCities: data_state.direction.data.is_nearby_cities,
                    zoneId: data_state.direction.data.zone_id,
                    shipmentTypeId: 1,
                    hasPickup,
                    hasSelfDelivery,
                    volumeWeights: [
                        {
                            width: Number(advance_paynemt_state.width as string),
                            length: Number(advance_paynemt_state.length as string),
                            height: Number(advance_paynemt_state.height as string),
                        },
                    ],
                    physicalWeights: [{ weight: Number(advance_paynemt_state.weight as string) }],
                    additionalServicesDelivery: ordering_state.additionalServicesDelivery,
                    additionalServicesTake: ordering_state.additionalServicesTake,
                }

                const response = await billingAPI.getClientCalculation(payload)
                billing_state.auto.tariff = response.data.result

                if (
                    billing_state.selected_tariff &&
                    billing_state.selected_tariff.shipmentTypeId ===
                        response.data.result.shipmentTypeId
                ) {
                    billing_state.selected_tariff = { ...response.data.result }
                }

                billing_state.auto.is_failed = false
            } catch (error) {
                billing_state.auto.is_failed = true
                console.error(error)

                notifications.failure({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'При получении расчета стоимости по тарифу "АВТО" произошла ошибка',
                    text: 'Пожалуйста, попробуйте ещё раз',
                })
            } finally {
                billing_state.auto.is_loading = false
            }
        }

        const loadAviaCalculation = async () => {
            billing_state.avia.tariff = null

            if (!data_state.direction.data) {
                return
            }

            try {
                billing_state.avia.is_loading = true

                let hasPickup = 0
                let hasSelfDelivery = 0

                if (ordering_state.sender && ordering_state.sender.warehouse_id) {
                    hasSelfDelivery = 1
                }

                if (ordering_state.receiver && ordering_state.receiver.warehouse_id) {
                    hasPickup = 1
                }

                const payload: ClientCalculationPayload = {
                    isFromMainCity: data_state.direction.data.is_from_main_city,
                    isNearbyCities: data_state.direction.data.is_nearby_cities,
                    zoneId: data_state.direction.data.zone_id,
                    shipmentTypeId: 2,
                    hasPickup,
                    hasSelfDelivery,
                    volumeWeights: [
                        {
                            width: Number(advance_paynemt_state.width as string),
                            length: Number(advance_paynemt_state.length as string),
                            height: Number(advance_paynemt_state.height as string),
                        },
                    ],
                    physicalWeights: [{ weight: Number(advance_paynemt_state.weight as string) }],
                    additionalServicesDelivery: ordering_state.additionalServicesDelivery,
                    additionalServicesTake: ordering_state.additionalServicesTake,
                }

                const response = await billingAPI.getClientCalculation(payload)
                billing_state.avia.tariff = response.data.result

                if (
                    billing_state.selected_tariff &&
                    billing_state.selected_tariff.shipmentTypeId ===
                        response.data.result.shipmentTypeId
                ) {
                    billing_state.selected_tariff = { ...response.data.result }
                }

                billing_state.avia.is_failed = false
            } catch (error) {
                billing_state.avia.is_failed = true
                console.error(error)

                notifications.failure({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'При получении расчета стоимости по тарифу "АВИА" произошла ошибка',
                    text: 'Пожалуйста, попробуйте ещё раз',
                })
            } finally {
                billing_state.avia.is_loading = false
            }
        }

        const getAdditionalServices = (
            additional_services: CalculationAdditionalServicePayload[]
        ): AdditionalServicesT => {
            let hasManipulator = Boolean(
                additional_services.find((service) => service.code === 'manipulator')
            )
            let hasCrane = Boolean(additional_services.find((service) => service.code === 'crane'))
            let hasHydraulicTrolley = Boolean(
                additional_services.find((service) => service.code === 'hydraulic_trolley')
            )
            let hasLoader = Boolean(
                additional_services.find((service) => service.code === 'loader')
            )
            let hasGrid = Boolean(additional_services.find((service) => service.code === 'grid'))
            let hasPallet = Boolean(
                additional_services.find((service) => service.code === 'pallet')
            )
            let hasSoftPackage = Boolean(
                additional_services.find((service) => service.code === 'soft_package')
            )

            return {
                hasCar: false,
                hasCrane,
                hasGrid,
                hasHydraulicTrolley,
                hasLoader,
                hasManipulator,
                hasPallet,
                hasSoftPackage,
            }
        }

        const loadInvoiceFiles = () => {
            return invoicesAPI.uploadFiles(
                getFormData({ type: 17, files: about_cargo_state.invoice_files })
            )
        }

        const loadCertificateFiles = () => {
            return invoicesAPI.uploadFiles(
                getFormData({ type: 18, files: about_cargo_state.certificate_of_safety_files })
            )
        }

        const getOrderPayload = async () => {
            const sender = ordering_state.sender as SenderT
            const receiver = ordering_state.receiver as ReceiverT
            const selected_tariff = billing_state.selected_tariff as ClientCalculation
            const period_id = (ordering_state.take_period as OptionT<string>).id

            const take_date = moment(ordering_state.take_date as Date).format('YYYY-MM-DD')

            let verify = null

            if (data_state.verify.type > 0) {
                if (about_cargo_state.verify) {
                    verify = data_state.verify.type
                } else {
                    verify = 0
                }
            }

            const width = Number(advance_paynemt_state.width),
                height = Number(advance_paynemt_state.height),
                length = Number(advance_paynemt_state.length)

            const volume_weight = (width * height * length) / 1000000
            const volume = volume_weight >= 0.01 ? Number(volume_weight.toFixed(2)) : 0.01

            const temperature_regime = about_cargo_state.temperature_regime
                ? Number(about_cargo_state.temperature_regime)
                : null

            let invoice_files = []
            let certificate_of_safety_files = []

            if (about_cargo_state.is_dangerous) {
                try {
                    about_cargo_state.is_files_loading = true

                    const invoice_files_response = await loadInvoiceFiles()
                    invoice_files = invoice_files_response.data

                    const certificate_of_safety_files_response = await loadCertificateFiles()
                    certificate_of_safety_files = certificate_of_safety_files_response.data
                } catch (error) {
                    console.error(error)

                    notifications.failure(
                        {
                            group: NOTIFICATIONS_GROUP,
                            type: 'error',
                            title: 'При загрузке файлов накладной произошла ошибка',
                            text: 'Пожалуйста, попробуйте ещё раз',
                        },
                        error
                    )
                } finally {
                    about_cargo_state.is_files_loading = false
                }
            }

            let payload: any = {
                title: sender.title,
                sender_id: sender.id,
                additional_service: getAdditionalServices(ordering_state.additionalServicesTake),
                receivers: [
                    {
                        receiver_id: receiver.id,
                        additional_service: getAdditionalServices(
                            ordering_state.additionalServicesDelivery
                        ),
                        order_logistics_info: {
                            verify,
                            product_name: about_cargo_state.product_name,
                            dop_invoice_number: about_cargo_state.customer_invoice_number,
                            places: Number(advance_paynemt_state.places),
                            volume,
                            weight: Number(advance_paynemt_state.weight),
                            width,
                            height,
                            depth: length,
                            payment_type: payment_state.payment_type,
                            payment_method: payment_state.payment_method,
                            shipment_type: selected_tariff.shipmentTypeId,
                            cod_payment: Number(about_cargo_state.cod_payment),
                            cargo_name: about_cargo_state.insurance_cargo_name,
                            declared_price: Number(about_cargo_state.declared_price),
                            should_return_document: about_cargo_state.is_return_documents ? 1 : 0,
                            take_date,
                            period_id,
                            annotation: about_cargo_state.annotation,
                            temperature_regime,
                            is_dangerous: about_cargo_state.is_dangerous ? 1 : 0,
                            invoice_files,
                            certificate_of_safety_files,
                        },
                    },
                ],
            }

            if (payment_state.payer) {
                payload.receivers.forEach((_: any, index: number) => {
                    if (payment_state.payer) {
                        payload.receivers[index].order_logistics_info.payer_company_id =
                            payment_state.payer.id
                    }
                })
            }

            return payload
        }

        const createOrder = async () => {
            if (state.is_order_creating) return

            advance_paynemt$.value.$touch()

            if (advance_paynemt$.value.$invalid) {
                notifications.alert({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Предварительный расчет заполнен некорректно',
                    text: 'Пожалуйста, попробуйте ещё раз',
                })

                return
            }

            if (!data_state.direction.data) {
                notifications.alert({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Направление не установлено',
                    text: 'Пожалуйста, попробуйте ещё раз',
                })

                return
            }

            if (!ordering_state.sender) {
                notifications.alert({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Отправитель не выбран',
                    text: 'Пожалуйста, выберите отправителя',
                })

                return
            }

            if (!ordering_state.receiver) {
                notifications.alert({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Получатель не выбран',
                    text: 'Пожалуйста, выберите получателя',
                })

                return
            }

            if (!ordering_state.take_date || !ordering_state.take_period) {
                notifications.alert({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Не указана дата или период отправки',
                    text: 'Пожалуйста, укажите дату и период отправки',
                })

                return
            }

            about_cargo$.value.$touch()

            if (about_cargo$.value.$invalid) {
                notifications.alert({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Информация о грузе заполнена некорректно',
                    text: 'Пожалуйста, попробуйте ещё раз',
                })

                return
            }

            if (!payment_state.payment_type) {
                notifications.alert({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Укажите кем будет производиться оплата',
                    text: 'Не указано поле "Кем будет производиться оплата"',
                })

                return
            }

            if (!payment_state.payment_method) {
                notifications.alert({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Укажите способ оплаты',
                    text: 'Не указано поле "Способ оплаты"',
                })

                return
            }

            payment$.value.$touch()

            if (payment$.value.$invalid) {
                notifications.alert({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Оплата заполнена некорректно',
                    text: 'Пожалуйста, попробуйте ещё раз',
                })

                return
            }

            try {
                state.is_order_creating = true

                const payload = await getOrderPayload()
                await invoicesAPI.createLTLOrder(payload)

                router.push('/history')

                notifications.alert({
                    type: 'success',
                    title: 'Заказ создан',
                    group: NOTIFICATIONS_GROUP,
                })

                if (!state.completed_steps.includes(PROGRESS_STEPS[4].id)) {
                    state.completed_steps.push(PROGRESS_STEPS[4].id)
                }
            } catch (error) {
                console.error(error)
            } finally {
                state.is_order_creating = false
            }
        }

        const onContinue = () => {
            if (state.current_step === PROGRESS_STEPS[0].id) {
                advance_paynemt$.value.$touch()
                if (advance_paynemt$.value.$invalid) return

                if (!data_state.direction.data) {
                    notifications.alert({
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Направление не установлено',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    })

                    return
                }

                billing_state.is_calculating = true

                loadAutoCalculation()
                loadAviaCalculation()

                state.current_step = PROGRESS_STEPS[1].id
                state.available_steps.push(PROGRESS_STEPS[1].id)

                if (!state.completed_steps.includes(PROGRESS_STEPS[0].id)) {
                    state.completed_steps.push(PROGRESS_STEPS[0].id)
                }

                return
            }

            if (state.current_step === PROGRESS_STEPS[1].id) {
                if (!ordering_state.sender) {
                    notifications.alert({
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Отправитель не выбран',
                        text: 'Пожалуйста, выберите отправителя',
                    })

                    return
                }

                if (!ordering_state.receiver) {
                    notifications.alert({
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Получатель не выбран',
                        text: 'Пожалуйста, выберите получателя',
                    })

                    return
                }

                if (!ordering_state.take_date || !ordering_state.take_period) {
                    notifications.alert({
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Не указана дата или период отправки',
                        text: 'Пожалуйста, укажите дату и период отправки',
                    })

                    return
                }

                state.current_step = PROGRESS_STEPS[2].id
                state.available_steps.push(PROGRESS_STEPS[2].id)

                if (!state.completed_steps.includes(PROGRESS_STEPS[1].id)) {
                    state.completed_steps.push(PROGRESS_STEPS[1].id)
                }

                return
            }

            if (state.current_step === PROGRESS_STEPS[2].id) {
                about_cargo$.value.$touch()
                if (about_cargo$.value.$invalid) return

                state.current_step = PROGRESS_STEPS[3].id
                state.available_steps.push(PROGRESS_STEPS[3].id)

                if (!state.completed_steps.includes(PROGRESS_STEPS[2].id)) {
                    state.completed_steps.push(PROGRESS_STEPS[2].id)
                }

                return
            }

            if (state.current_step === PROGRESS_STEPS[3].id) {
                if (!payment_state.payment_type) {
                    notifications.alert({
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Укажите кем будет производиться оплата',
                        text: 'Не указано поле "Кем будет производиться оплата"',
                    })

                    return
                }

                if (!payment_state.payment_method) {
                    notifications.alert({
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Укажите способ оплаты',
                        text: 'Не указано поле "Способ оплаты"',
                    })

                    return
                }

                payment$.value.$touch()
                if (payment$.value.$invalid) return

                state.current_step = PROGRESS_STEPS[4].id
                state.available_steps.push(PROGRESS_STEPS[4].id)

                if (!state.completed_steps.includes(PROGRESS_STEPS[3].id)) {
                    state.completed_steps.push(PROGRESS_STEPS[3].id)
                }

                return
            }

            if (state.current_step === PROGRESS_STEPS[4].id) {
                if (!billing_state.selected_tariff) {
                    notifications.alert({
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Не выбран тариф',
                        text: 'Пожалуйста, выберите тариф',
                    })

                    return
                }

                createOrder()
            }
        }

        const resetPaymentStep = () => {
            payment_state.payer = null
            payment_state.payment_method = null
            payment_state.payment_type = null
        }

        const removeDeliveryAdditionalService = (service_code: string) => {
            const index = ordering_state.additionalServicesDelivery.findIndex(
                (service) => service.code === service_code
            )
            if (index < 0) return
            ordering_state.additionalServicesDelivery.splice(index, 1)
        }

        const resetAboutCargoStep = () => {
            about_cargo_state.certificate_of_safety_files = []
            about_cargo_state.invoice_files = []

            about_cargo_state.cod_payment = null
            about_cargo_state.product_name = null
            about_cargo_state.annotation = null
            about_cargo_state.temperature_regime = null
            about_cargo_state.customer_invoice_number = null
            about_cargo_state.declared_price = null
            about_cargo_state.insurance_cargo_name = null

            about_cargo_state.is_insurance = false
            about_cargo_state.is_return_documents = false
            about_cargo_state.is_temperature_regime = false
            about_cargo_state.is_dangerous = false

            if (data_state.verify.type) {
                about_cargo_state.verify = true
            } else {
                about_cargo_state.verify = false
            }

            about_cargo_state.hasGrid = false
            removeDeliveryAdditionalService('grid')

            about_cargo_state.hasPallet = false
            removeDeliveryAdditionalService('pallet')

            about_cargo_state.hasSoftPackage = false
            removeDeliveryAdditionalService('soft_package')

            recalculateTariffs()
        }

        const resetOrderingStep = () => {
            ordering_state.additionalServicesDelivery = []
            ordering_state.additionalServicesTake = []

            ordering_state.is_additional_services_on_delivery_setted = false
            ordering_state.is_additional_services_on_take_setted = false

            about_cargo_state.hasGrid = false
            about_cargo_state.hasPallet = false
            about_cargo_state.hasSoftPackage = false

            ordering_state.sender = null

            payment_state.payer = null
            data_state.payers.list = []

            ordering_state.receiver = null

            ordering_state.is_date_setted = false
            ordering_state.take_date = null
            ordering_state.take_period = null

            recalculateTariffs()
        }

        const resetAdvancePaynemtStep = () => {
            advance_paynemt_state.city_from = null
            advance_paynemt_state.city_to = null
            data_state.direction.data = null
            advance_paynemt_state.height = null
            advance_paynemt_state.length = null
            advance_paynemt_state.places = null
            advance_paynemt_state.weight = null
            advance_paynemt_state.width = null

            ordering_state.sender = null
            data_state.senders.current_page = 1
            data_state.senders.total_pages = 1
            data_state.senders.list = []

            payment_state.payer = null
            data_state.payers.list = []

            ordering_state.receiver = null
            data_state.receivers.current_page = 1
            data_state.receivers.total_pages = 1
            data_state.receivers.list = []

            billing_state.selected_tariff = null
            billing_state.auto.tariff = null
            billing_state.avia.tariff = null
        }

        const reset = () => {
            const answer = confirm('Вы действительно хотите сбросить создание заказа?')
            if (!answer) return

            // Advance paynemnt
            advance_paynemt_state.city_from = null
            ordering_state.sender = null
            data_state.senders.current_page = 1
            data_state.senders.total_pages = 1
            data_state.senders.list = []
            data_state.payers.list = []

            advance_paynemt_state.city_to = null
            ordering_state.receiver = null
            data_state.receivers.current_page = 1
            data_state.receivers.total_pages = 1
            data_state.receivers.list = []

            data_state.direction.data = null

            advance_paynemt_state.height = null
            advance_paynemt_state.length = null
            advance_paynemt_state.places = null
            advance_paynemt_state.weight = null
            advance_paynemt_state.width = null
            advance_paynemt$.value.$reset()

            // Ordering
            ordering_state.additionalServicesDelivery = []
            ordering_state.additionalServicesTake = []
            ordering_state.is_additional_services_on_delivery_setted = false
            ordering_state.is_additional_services_on_take_setted = false
            ordering_state.is_date_setted = false
            ordering_state.take_date = null
            ordering_state.take_period = null

            // About Cargo
            about_cargo_state.certificate_of_safety_files = []
            about_cargo_state.invoice_files = []
            about_cargo_state.cod_payment = null
            about_cargo_state.product_name = null
            about_cargo_state.annotation = null
            about_cargo_state.temperature_regime = null
            about_cargo_state.customer_invoice_number = null
            about_cargo_state.declared_price = null
            about_cargo_state.insurance_cargo_name = null
            about_cargo_state.is_insurance = false
            about_cargo_state.is_return_documents = false
            about_cargo_state.is_temperature_regime = false
            about_cargo_state.is_dangerous = false

            if (data_state.verify.type) {
                about_cargo_state.verify = true
            } else {
                about_cargo_state.verify = false
            }

            about_cargo_state.hasGrid = false
            about_cargo_state.hasPallet = false
            about_cargo_state.hasSoftPackage = false
            about_cargo$.value.$reset()

            // Payment
            payment_state.payer = null
            payment_state.payment_method = null
            payment_state.payment_type = null

            // Billing
            billing_state.is_calculating = false
            billing_state.selected_tariff = null
            billing_state.auto.tariff = null
            billing_state.avia.tariff = null

            // STEPS
            state.current_step = PROGRESS_STEPS[0].id
            state.completed_steps = []
            state.available_steps = [1]
        }

        const onCancel = () => {
            if (state.current_step === PROGRESS_STEPS[0].id) {
                resetAdvancePaynemtStep()
                return
            }

            if (state.current_step === PROGRESS_STEPS[1].id) {
                resetOrderingStep()
                return
            }

            if (state.current_step === PROGRESS_STEPS[2].id) {
                resetAboutCargoStep()
                return
            }

            if (state.current_step === PROGRESS_STEPS[3].id) {
                resetPaymentStep()
                return
            }

            if (state.current_step === PROGRESS_STEPS[4].id) {
                reset()
                return
            }
        }

        const loadDirection = async () => {
            advance_paynemt$.value.city_from.$touch()
            advance_paynemt$.value.city_to.$touch()

            if (
                advance_paynemt$.value.city_from.$invalid ||
                advance_paynemt$.value.city_to.$invalid
            )
                return

            data_state.direction.data = null

            try {
                data_state.direction.is_loading = true

                const fromCityId = (advance_paynemt_state.city_from as CityT).id
                const whereCityId = (advance_paynemt_state.city_to as CityT).id

                const params = {
                    page: 1,
                    fromCityId,
                    whereCityId,
                    isExtended: 1,
                }

                const response = await directionsAPI.getDirections(params)

                if (response.data.data.length > 0) {
                    data_state.direction.data = response.data.data[0]
                    recalculateTariffs()
                }

                data_state.direction.is_failed = false
            } catch (error) {
                data_state.direction.is_failed = true
                console.error(error)

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'При получении направления произошла ошибка',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    },
                    error
                )
            } finally {
                data_state.direction.is_loading = false
            }
        }

        const onToCitySelect = (to_city: CityT | null) => {
            ordering_state.receiver = null

            if (!to_city) return

            advance_paynemt_state.city_to = { ...to_city }
            loadReceivers(to_city.id)
            loadDirection()
        }

        const closeTemplateForm = () => {
            template_form.is_opened = false
            template_form.is_receiver_template = false
            template_form.initial_value = null
            template_form.is_update = false
        }

        const startCreateSender = () => {
            template_form.is_update = false
            template_form.initial_value = null
            template_form.is_receiver_template = false
            template_form.is_opened = true
        }

        const startUpdateSender = (sender: SenderT) => {
            template_form.is_update = true
            template_form.initial_value = { ...sender }
            template_form.is_receiver_template = false
            template_form.is_opened = true
        }

        const startCreateReceiver = () => {
            template_form.is_update = false
            template_form.initial_value = null
            template_form.is_receiver_template = true
            template_form.is_opened = true
        }

        const startUpdateReceiver = (receiver: ReceiverT) => {
            template_form.is_update = true
            template_form.initial_value = { ...receiver }
            template_form.is_receiver_template = true
            template_form.is_opened = true
        }

        const onCreateSender = async (payload: SenderPayloadT) => {
            try {
                template_form.is_loading = true

                await sendersAPI.create(payload)

                const from_city_id = (advance_paynemt_state.city_from as CityT).id
                loadSenders(from_city_id)

                notifications.success({
                    group: NOTIFICATIONS_GROUP,
                    title: 'Отправитель создан',
                    type: 'success',
                })

                closeTemplateForm()
            } catch (error) {
                console.error(error)

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'При создании отправителя произошла ошибка',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    },
                    error
                )
            } finally {
                template_form.is_loading = false
            }
        }

        const onUpdateSender = async ({ id, payload }: { id: number; payload: SenderPayloadT }) => {
            try {
                template_form.is_loading = true

                await sendersAPI.update(id, payload)

                const from_city_id = (advance_paynemt_state.city_from as CityT).id
                loadSenders(from_city_id)

                notifications.success({
                    group: NOTIFICATIONS_GROUP,
                    title: 'Отправитель сохранен',
                    type: 'success',
                })

                closeTemplateForm()
            } catch (error) {
                console.error(error)

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'При сохранении отправителя произошла ошибка',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    },
                    error
                )
            } finally {
                template_form.is_loading = false
            }
        }

        const onCreateReceiver = async (payload: ReceiverPayloadT) => {
            try {
                template_form.is_loading = true

                await receiversAPI.create(payload)

                const where_city_id = (advance_paynemt_state.city_to as CityT).id
                loadReceivers(where_city_id)

                notifications.success({
                    group: NOTIFICATIONS_GROUP,
                    title: 'Получатель создан',
                    type: 'success',
                })

                closeTemplateForm()
            } catch (error) {
                console.error(error)

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'При создании получателя произошла ошибка',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    },
                    error
                )
            } finally {
                template_form.is_loading = false
            }
        }

        const onUpdateReceiver = async ({
            id,
            payload,
        }: {
            id: number
            payload: ReceiverPayloadT
        }) => {
            try {
                template_form.is_loading = true

                await receiversAPI.update(id, payload)

                const where_city_id = (advance_paynemt_state.city_to as CityT).id
                loadReceivers(where_city_id)

                notifications.success({
                    group: NOTIFICATIONS_GROUP,
                    title: 'Получатель сохранен',
                    type: 'success',
                })

                closeTemplateForm()
            } catch (error) {
                console.error(error)

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'При сохранении получателя произошла ошибка',
                        text: 'Пожалуйста, попробуйте ещё раз',
                    },
                    error
                )
            } finally {
                template_form.is_loading = false
            }
        }

        const setTakeDate = (payload: LTLCalendarT) => {
            ordering_state.take_date = payload.send_date
            ordering_state.take_period = payload.send_period
            ordering_state.is_date_setted = true
        }

        const resetTakeDate = () => {
            ordering_state.take_date = null
            ordering_state.take_period = null
            ordering_state.is_date_setted = false
        }

        const onInsuranceToggle = (is_insurance: boolean) => {
            if (!is_insurance) {
                about_cargo_state.insurance_cargo_name = null
                about_cargo_state.declared_price = null
            }
        }

        const onDangerousToggle = (is_dangerous: boolean) => {
            if (!is_dangerous) {
                about_cargo_state.invoice_files = []
                about_cargo_state.certificate_of_safety_files = []
            }
        }

        const onTemperatureToggle = (is_temperature_regime: boolean) => {
            if (!is_temperature_regime) {
                about_cargo_state.temperature_regime = null
            }
        }

        const loadPayers = async () => {
            if (!senderCompanyId.value) return

            try {
                data_state.payers.is_loading = true

                const response = await ReceiversRealisation.getPayers(senderCompanyId.value, 1)
                data_state.payers.list = response.data.map((option) => {
                    return { ...option, value: option.id }
                })

                data_state.payers.is_failed = false
            } catch (error) {
                console.error(error)
                data_state.payers.is_failed = true
            } finally {
                data_state.payers.is_loading = false
            }
        }

        const getServiceName = (service_code: string) => {
            switch (service_code) {
                case 'crane':
                    return 'Кран'
                case 'hydraulic_trolley':
                    return 'Рохля'
                case 'loader':
                    return 'Грузчики'
                case 'manipulator':
                    return 'Манипулятор'
                case 'soft_package':
                    return 'Мягкая упаковка'
                case 'grid':
                    return 'Обрешетка'
                case 'pallet':
                    return 'Паллета'
            }
        }

        const onGridChange = (is_checked: boolean) => {
            if (is_checked) {
                ordering_state.additionalServicesDelivery.push({
                    code: 'grid',
                    value: 0,
                    duration: 0,
                    costPerHour: 0,
                    costTotal: 0,
                    paidPricePerHour: 0,
                    paidPriceTotal: 0,
                })
            } else {
                const index = ordering_state.additionalServicesDelivery.findIndex(
                    (service) => service.code === 'grid'
                )
                if (index >= 0) {
                    ordering_state.additionalServicesDelivery.splice(index, 1)
                }
            }

            recalculateTariffs()
        }

        const onPalletChange = (is_checked: boolean) => {
            if (is_checked) {
                ordering_state.additionalServicesDelivery.push({
                    code: 'pallet',
                    value: 0,
                    duration: 0,
                    costPerHour: 0,
                    costTotal: 0,
                    paidPricePerHour: 0,
                    paidPriceTotal: 0,
                })
            } else {
                const index = ordering_state.additionalServicesDelivery.findIndex(
                    (service) => service.code === 'pallet'
                )
                if (index >= 0) {
                    ordering_state.additionalServicesDelivery.splice(index, 1)
                }
            }

            recalculateTariffs()
        }

        const onSoftPackageChange = (is_checked: boolean) => {
            if (is_checked) {
                ordering_state.additionalServicesDelivery.push({
                    code: 'soft_package',
                    value: 0,
                    duration: 0,
                    costPerHour: 0,
                    costTotal: 0,
                    paidPricePerHour: 0,
                    paidPriceTotal: 0,
                })
            } else {
                const index = ordering_state.additionalServicesDelivery.findIndex(
                    (service) => service.code === 'soft_package'
                )
                if (index >= 0) {
                    ordering_state.additionalServicesDelivery.splice(index, 1)
                }
            }

            recalculateTariffs()
        }

        const selectTariff = (key: 'auto' | 'avia') => {
            if (key === 'auto' && billing_state.auto.tariff) {
                billing_state.selected_tariff = { ...billing_state.auto.tariff }
                return
            }

            if (key === 'avia' && billing_state.avia.tariff) {
                billing_state.selected_tariff = { ...billing_state.avia.tariff }
                return
            }
        }

        const isItemSelected = (key: 'auto' | 'avia') => {
            if (!billing_state.selected_tariff) return false

            if (key === 'auto') {
                if (!billing_state.auto.tariff) return false
                return (
                    billing_state.selected_tariff.shipmentTypeId ===
                    billing_state.auto.tariff.shipmentTypeId
                )
            }

            if (key === 'avia') {
                if (!billing_state.avia.tariff) return false
                return (
                    billing_state.selected_tariff.shipmentTypeId ===
                    billing_state.avia.tariff.shipmentTypeId
                )
            }

            return false
        }

        const loadVerifyType = async () => {
            try {
                data_state.verify.is_loading = true

                const response = await usersAPI.getProfile()
                data_state.verify.type = response.data.company.verify

                if (data_state.verify.type) {
                    about_cargo_state.verify = true
                }

                data_state.verify.is_failed = false
            } catch (error) {
                notifications.failure(
                    {
                        title: 'При получении типа верификации произошла ошибка',
                        type: 'error',
                        group: NOTIFICATIONS_GROUP,
                    },
                    error
                )

                data_state.verify.is_failed = true
            } finally {
                data_state.verify.is_loading = false
            }
        }

        const changeActiveStep = (step_id: number) => {
            state.current_step = step_id
        }

        const onPaymentMethodChange = () => {
            payment_state.payer = null
            payment_state.payment_type = null
        }

        const onPaymentTypeChange = () => {
            payment_state.payer = null
        }

        const parameters = computed(() => {
            const length = advance_paynemt_state.length || 0
            const height = advance_paynemt_state.height || 0
            const width = advance_paynemt_state.width || 0

            return `${length}x${height}x${width}`
        })

        const weight = computed(() => {
            if (advance_paynemt_state.weight) {
                return `${advance_paynemt_state.weight} кг`
            }

            return null
        })

        const continueButtonText = computed(() => {
            if (state.current_step === PROGRESS_STEPS[4].id) {
                return 'Создать заказ'
            }

            return 'Продолжить'
        })

        const selectedSenders = computed(() => {
            if (ordering_state.sender) {
                return [ordering_state.sender.id]
            }

            return []
        })

        const selectedReceivers = computed(() => {
            if (ordering_state.receiver) {
                return [ordering_state.receiver.id]
            }

            return []
        })

        const templateFormExactCityId = computed(() => {
            if (template_form.is_receiver_template && advance_paynemt_state.city_to) {
                return advance_paynemt_state.city_to.id
            }

            if (!template_form.is_receiver_template && advance_paynemt_state.city_from) {
                return advance_paynemt_state.city_from.id
            }

            return null
        })

        const senderCompanyId = computed(() => {
            if (ordering_state.sender) {
                return ordering_state.sender.company_id
            }

            return null
        })

        const formattedTakeDate = computed(() => {
            if (ordering_state.take_date) {
                const year = ordering_state.take_date.getFullYear()
                let month: number | string = ordering_state.take_date.getMonth() + 1
                let day: number | string = ordering_state.take_date.getDate()

                if (day < 10) day = '0' + day
                if (month < 10) month = '0' + month

                return `${day}.${month}.${year}`
            }

            return null
        })

        const customInvoiceNumberPlaceholder = computed(() => {
            if (data_state.verify.type === 2 && about_cargo_state.verify)
                return 'Номер накладной Kaspi'
            return 'Номер накладной заказчика'
        })

        const disabledPaymentTypeOptionsValues = computed(() => {
            if (payment_state.payment_method === 3) {
                return []
            }

            return [3]
        })

        const senderCompanyName = computed(() => {
            if (ordering_state.sender) {
                return ordering_state.sender.company_name
            }

            return null
        })

        return {
            PROGRESS_STEPS,
            //
            state,
            data_state,
            billing_state,
            template_form,
            //
            advance_paynemt_state,
            advance_paynemt$,
            ordering_state,
            about_cargo_state,
            about_cargo$,
            payment_state,
            payment$,
            //
            onContinue,
            onCancel,
            loadToCities,
            onToCitySelect,
            setAdditionalServicesOnDelivery,
            resetAdditionalServicesOnDelivery,
            setAdditionalServicesOnTake,
            resetAdditionalServicesOnTake,
            setTakeDate,
            resetTakeDate,
            onInsuranceToggle,
            onDangerousToggle,
            onTemperatureToggle,
            //
            loadMoreSenders,
            searchSender,
            onSelectSender,
            onDeleteSender,
            startCreateSender,
            startUpdateSender,
            onCreateSender,
            onUpdateSender,
            //
            loadMoreReceivers,
            searchReceiver,
            onSelectReceiver,
            onDeleteReceiver,
            startCreateReceiver,
            startUpdateReceiver,
            onCreateReceiver,
            onUpdateReceiver,
            //
            closeTemplateForm,
            getServiceName,
            recalculateTariffs,
            selectTariff,
            isItemSelected,
            changeActiveStep,
            onPaymentMethodChange,
            onPaymentTypeChange,
            //
            onGridChange,
            onPalletChange,
            onSoftPackageChange,
            //
            parameters,
            weight,
            continueButtonText,
            selectedSenders,
            selectedReceivers,
            templateFormExactCityId,
            senderCompanyId,
            formattedTakeDate,
            customInvoiceNumberPlaceholder,
            disabledPaymentTypeOptionsValues,
            senderCompanyName,
        }
    },
})
