
import { defineComponent, PropType } from 'vue'

import { PAYMENT_TYPE_OPTIONS } from '@/core/constants/common.constants'
import { OptionT } from '@/core/types/common.types'

export default defineComponent({
    props: {
        value: {
            type: [Number, null] as PropType<number | null>,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        payersLength: {
            type: Number,
            default: 0
        },
        disabledOptionsValues: {
            type: Array as PropType<number[]>,
            default: () => []
        },
        senderCompanyName: {
            type: String,
            default: null
        }
    },
    emits: ['update:value', 'change'],
    setup(props, { emit }) {
        const isOptionSelected = (option: OptionT<number>) => {
            return option.value === props.value
        }

        const onOptionSelect = (option: OptionT<number>) => {
            if (props.disabled) return
            if (isOptionSelected(option)) return

            emit('update:value', option.value)
            emit('change', option.value)
        }

        const isOptionDisabled = (option: OptionT<number>) => {
            return props.disabledOptionsValues.includes(option.value)
        }

        return {
            isOptionSelected,
            onOptionSelect,
            isOptionDisabled,
            PAYMENT_TYPE_OPTIONS
        }
    }
})
