
import { computed, defineComponent, PropType, reactive } from 'vue'

// @components
import SInput from '@/common/components/SInput/index.vue'
import NewButton from '@/common/new-components/NewButton/index.vue'
import SLoader from '@/common/components/SLoader/index.vue'

import iSearch from '@/assets/icons/Search.svg'
import iBigPlus from '@/assets/icons/BigPlus.svg'

import TemplateCard from '@/modules/CalculatorModule/components/TemplateCard/index.vue'

// @types
import { MemberT } from '@/core/types/common.types'

// @composable
import { useDebounce } from 'vue-composable'

export default defineComponent({
    components: {
        SInput,
        NewButton,
        SLoader,
        TemplateCard,
        'icon-search': iSearch,
        'icon-big-plus': iBigPlus,
    },
    props: {
        title: {
            type: String,
            default: 'Title'
        },
        createText: {
            type: String,
            default: 'create text'
        },
        currentPage: {
            type: Number,
            default: 1
        },
        totalPages: {
            type: Number,
            default: 1
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        templates: {
            type: Array as PropType<MemberT[]>,
            default: () => []
        },
        selectedTemplatesIds: {
            type: Array as PropType<number[]>,
            default: () => []
        },
        isReceiverTemplates: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['load-more', 'create', 'search', 'select-template', 'update-template', 'delete-template'],
    setup(props, { emit }) {
        const state = reactive({
            query: null as string | null
        })

        const onSearch = useDebounce(() => {
            if (props.loading) return
            emit('search', state.query)
        }, 1000)

        const onCreate = () => {
            if (props.loading) return
            emit('create')
        }

        const onLoadMore = () => {
            if (props.loading) return
            emit('load-more')
        }

        const onTemplateSelect = (template: MemberT) => {
            if (props.loading) return

            if (!template.latitude || !template.longitude) {
                emit('update-template', template)
                return
            }

            emit('select-template', template)
        }

        const onTemplateUpdate = (template: MemberT) => {
            if (props.loading) return
            emit('update-template', template)
        }

        const onTemplateDelete = (template: MemberT) => {
            if (props.loading) return
            emit('delete-template', template)
        }

        const isTemplateSelected = (template: MemberT) => {
            return props.selectedTemplatesIds.includes(template.id as number)
        }

        const isCanLoadMore = computed(() => props.currentPage < props.totalPages)

        return {
            state,
            //
            onLoadMore,
            onSearch,
            onCreate,
            //
            onTemplateSelect,
            onTemplateUpdate,
            onTemplateDelete,
            //
            isTemplateSelected,
            isCanLoadMore,
        }
    }
})
