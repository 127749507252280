
import { computed, defineComponent, reactive, watch } from 'vue'

// @components
import SCalendar from '@/common/components/SCalendar/index.vue'
import NewButton from '@/common/new-components/NewButton/index.vue'

// @types
import { OptionT } from '@/core/types/common.types'

// @realisations
import { SendersRealisation } from '@/core/realisations/Senders.realisation'

// @composable
import { useCalendar } from '@/common/composable/useCalendar'

export default defineComponent({
    components: {
        's-calendar': SCalendar,
        NewButton
    },
    props: {
        setted: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['submit', 'reset'],
    setup(props, { emit }) {
        const { getDayName, getMonthName } = useCalendar()

        const sendersAPI = new SendersRealisation()

        const state = reactive({
            date: null as Date | null,
            period: null as OptionT<string> | null
        })

        const schedule_state = reactive({
            is_loading: false,
            is_can_submit: false
        })

        const resetCalendar = () => {
            state.date = null
            state.period = null
        }

        const onUpdateDate = async (date: Date) => {
            let date_string = JSON.stringify(date)
            let date_payload = date_string ? date_string.replace('"', '').split('T')[0] : null

            if (!date_payload) return

            try {
                schedule_state.is_loading = true

                const response = await sendersAPI.checkCompanySchedule(date_payload)
                schedule_state.is_can_submit = response.data.isOpen
            } catch (error) {
                console.error(error)
            } finally {
                schedule_state.is_loading = false
            }
        }

        const onSubmit = () => {
            const payload = {
                send_date: state.date as Date,
                send_period: state.period as OptionT<string>
            }

            emit('submit', payload)
        }

        const onReset = () => {
            if (props.disabled) return
            
            state.date = null
            state.period = null

            emit('reset')
        }

        const isCantSubmit = computed(() => {
            if (props.disabled) return true
            if (!state.date || !state.period || schedule_state.is_loading) return true
            return false
        })

        const isShowWarning = computed(() => {
            if (state.date && !schedule_state.is_can_submit && !schedule_state.is_loading) return true
            return false
        })

        watch(() => props.setted, (is_setted: boolean) => {
            if (!is_setted) {
                resetCalendar()
            }
        })

        return {
            state,
            schedule_state,
            //
            resetCalendar,
            onUpdateDate,
            onSubmit,
            onReset,
            //
            getDayName,
            getMonthName,
            //
            isCantSubmit,
            isShowWarning
        }
    }
})
